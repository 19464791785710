<template>
  <component :is="commonComponent" id="blog" classs="blog">
    <div class="blog-container pc_padding m_set_width">
      <h1 class="m-b-30 head-1">
        What does the Bible say about judging others
      </h1>

      <div class="autor m-b-30">
        <div>Author: Irene </div>
        <div>Publish: 2025/1/3 </div>
      </div>

      <AdComponent
        v-if="deviceType == 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.blog"
        ref="ads-blog-1" class="ads-insert-max m-t-40" :ads="adsensConfig?.blog"
        :showDebug="showDebug" />

      <p>
        In our daily lives, we often find ourselves in the position of passing judgment on others.
        Whether it&#39;s a quick assessment of someone&#39;s behavior, appearance, or choices, we
        seem to have an innate tendency to form opinions and judgments. But what does the Bible have
        to say about this all-too-common human trait?
      </p>

      <h2>Don’t judge others</h2>

      <p>
        The Bible clearly warns against judgmental attitudes. In Matthew 7:1-2, Jesus says,
        &quot;Judge not, that you be not judged. For with the judgment you pronounce, you will be
        judged, and with the measure you use it will be measured for you.&quot; This passage is a
        powerful reminder that when we judge others, we set a standard by which we ourselves will be
        judged. It implies that we are not in a position to pass final judgment on anyone, for we
        are all sinners in need of God&#39;s mercy.
      </p>

      <p>
        <img alt="img"
          src="@/assets/ZGRkOTcxOTNhNTJjNmU0NGVhMzdmMWNmZmY1YTA2ZWJfUTNHM21ZdXl4QVJHcGdsakxlRHFXZFNEaWJsOURSMEpfVG9rZW46QlpzcmJIZFN3bzRNazZ4cXJhUGNhQUZrblNiXzE3MzMzODEyMjM6MTczMzM4NDgyM19WNA.png">
      </p>

      <h2>Don’t judge others’ reasons</h2>

      <ol class="in-page-ol">
        <li>
          One of the main reasons the Bible discourages judgment is that we are all fallible human
          beings. None of us is perfect, and we all have our own flaws and weaknesses. Romans 3:23
          states, &quot;For all have sinned and fall short of the glory of God.&quot; If we are all
          sinners, then who are we to condemn others? We cannot see into the hearts and minds of
          others, nor do we know their struggles and circumstances. What may seem like wrong
          behavior to us could be the result of a complex set of factors that we are unaware of.
        </li>
        <li>
          Moreover, judgment often leads to pride and self-righteousness. When we judge others, we
          are essentially elevating ourselves above them, believing that we are better or more
          righteous. This kind of attitude is contrary to the teachings of the Bible. In Luke
          18:9-14, Jesus tells the parable of the Pharisee and the tax collector. The Pharisee, who
          was confident in his own righteousness and looked down on others, was condemned by Jesus.
          On the other hand, the tax collector, who humbly acknowledged his sins and asked for
          God&#39;s mercy, was justified. This parable shows that those who are humble and recognize
          their own shortcomings are more likely to receive God&#39;s favor than those who are
          judgmental and self-righteous.
        </li>
        <li>
          Another important aspect to consider is that judgment can cause division and strife.
          When we judge others, we create barriers between ourselves and them. We alienate those who
          may need our love and support the most. In James 4:11-12, it is written, &quot;Do not
          speak evil against one another, brothers. Whoever speaks evil against a brother or judges
          his brother, speaks evil against the law and judges the law. But if you judge the law, you
          are not a doer of the law but a judge. There is only one lawgiver and judge, he who is
          able to save and to destroy. But who are you to judge your neighbor?&quot; This passage
          emphasizes the unity and harmony that should exist among believers and warns against the
          destructive nature of judgment.
        </li>
      </ol>

      <h2>Wise judgment</h2>

      <p>
        However, it is important to note that the Bible does not completely prohibit all forms of
        judgment. In fact, there is a distinction between judgmental attitudes and making wise
        discernment. For example, in 1 Corinthians 5:12-13, Paul says, &quot;For what have I to do
        with judging outsiders? Is it not those inside the church whom you are to judge? God judges
        those outside. &#39;Purge the evil person from among you.&#39;&quot; Here, Paul is not
        advocating for a harsh and judgmental attitude but rather for the church to exercise
        discipline and discernment within its own community. This kind of judgment is not based on
        personal opinions or self-righteousness but on the principles and standards set forth in the
        Bible.
      </p>

      <p>
        <img alt="img"
          src="@/assets/YWIzYjQzYzI3MzU0MTE4ZTY5N2IwMjEzOTYxNmFjZGVfS1NFZU00eDhJYlA1RXRFU1pQVjEyWTJqT1E3MzFSQ0RfVG9rZW46Rm4yZWJOM3JUbzlralN4Q0tRbGNCWVNFbjRiXzE3MzMzODEyMjM6MTczMzM4NDgyM19WNA.png">
      </p>

      <h2>How to avoid judging</h2>

      <p>
        So, how can we avoid being judgmental while still making wise decisions and discerning
        right from wrong?
      </p>

      <ul class="in-page-ul">
        <li>
          First and foremost, we need to cultivate a spirit of humility. We must recognize our own
          imperfections and weaknesses and be quick to extend grace and mercy to others. As James
          4:6 says, &quot;But he gives more grace. Therefore it says, &#39;God opposes the proud,
          but gives grace to the humble.&#39;&quot; When we are humble, we are less likely to be
          judgmental and more likely to show compassion and understanding.
        </li>
        <li>
          We also need to practice empathy. Instead of immediately passing judgment on others, we
          should try to put ourselves in their shoes and understand their perspective. This
          doesn&#39;t mean that we condone wrong behavior, but it helps us to approach others with
          kindness and respect. Proverbs 18:13 says, &quot;He who answers before listening—that is
          his folly and his shame.&quot; By listening and understanding, we can gain a better
          understanding of the situation and respond in a more appropriate way.
        </li>
        <li>
          Finally, we should focus on our own spiritual growth and transformation. Instead of
          spending our time and energy judging others, we should be examining our own hearts and
          lives. As Matthew 7:3-5 says, &quot;Why do you see the speck that is in your brother&#39;s
          eye, but do not notice the log that is in your own eye? Or how can you say to your
          brother, &#39;Let me take the speck out of your eye,&#39; when there is the log in your
          own eye? You hypocrite, first take the log out of your own eye, and then you will see
          clearly to take the speck out of your brother&#39;s eye.&quot; This passage reminds us
          that we should deal with our own sins and shortcomings before attempting to correct
          others.
        </li>
      </ul>

      <h2>Conclusion</h2>

      <p>
        In conclusion, the Bible offers a balanced perspective on judgment. While it clearly warns
        against judgmental attitudes and self-righteousness, it also recognizes the need for
        discernment and discipline within the community of believers. By cultivating humility,
        empathy, and a focus on our own spiritual growth, we can avoid being judgmental and instead
        show love, grace, and mercy to others. As we strive to live according to the teachings of
        the Bible, let us remember that we are all on a journey of spiritual growth and that none of
        us has the right to pass final judgment on anyone.
      </p>
    </div>

    <AdComponent
      v-if="deviceType != 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.blog"
      ref="ads-blog-1" class="pc_padding m-t-40" :ads="adsensConfig?.blog" :showDebug="showDebug" />

    <div class="blog-list-container ">
      <div class="blog-list-title">
        Recommend Reading
      </div>
      <div class="blog-card-row">
        <div v-for="(data, i) in card" :key="i" class="blog-card-item cursor"
          @click="goto(data.routename)">
          <img :src="data.img" class="blog-card-image" :alt="data.title">
          <div class="blog-card-content">
            <div class="blog-card-title">
              {{ data.title }}
            </div>
            <div class="blog-card-desc">
              {{ data.desc }}
            </div>
          </div>
          <div class="blog-card-btn">
            Read More <img src="@/assets/readmore.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <a href="/blogs">
      <div class="blog-all-btn cursor">
        View All
      </div>
    </a>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
const PcCommon = () => import('@/pages/components/page_common.vue');
const MobileCommon = () => import('@/pages/components/m_back_common.vue');
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/blog_base.scss';

export default {
  name: 'Blog3',
  components: {
    AdComponent,
  },
  mixins: [common],
  data () {
    return {
      commonComponent: null,
      card: [
        {
          img: require('@/assets/blog-1.png'),
          title: 'Seven Powerful Ways of Thinking in the Bible',
          desc: "German writer Goethe said that all my profound thoughts come from my belief in the Bible; my moral life and literary works are all guided by the Bible. The Bible is the most reliable and powerful capital in my life, and it is really an inexhaustible treasure house. Understanding these 7 kinds of thinking in the Bible can help you embark on a path of understanding and have lively hope in life.",
          routename: 'seven-powerful-ways-of-thinking-in-the-bible',
        },
        {
          img: require('@/assets/blog-2.png'),
          title: 'Greed in the Bible',
          desc: "The concept of coveting in the Bible is highly significant for understanding its morality and ethics. Essentially, coveting is having a strong longing for what belongs to others. This simple yet profound concept, deeply rooted in Biblical teachings like the Ten Commandments, reveals much about human nature and spiritual expectations.In this article, we will take a deeper look at greed as described in the Bible.",
          routename: 'greed-in-the-bible',
        },
        {
          img: require('@/assets/blog-4.png'),
          title: 'Revelation from the Stories of People Who Overcame Suffering in the Bible',
          desc: "In the Bible, there are many people who have experienced suffering. They showed admirable tenacity and courage in difficult situations. With their faith and strength, they turned suffering into grace, making life and living more abundant and meaningful. Next, let us learn how they faced suffering, got out of difficulties, and made life better.",
          routename: 'revelation-from-the-stories-of-people-who-overcame-suffering-in-the-bible',
        },

      ],
    };
  },
  computed: {
    title () {
      return `What does the Bible say about judging others | ${this.domainConfig?.['meta']?.title || 'Default Title'}`
    },
    description () {
      return `In our daily lives, we often find ourselves in the position of passing judgment on others. Whether it's a quick assessment of someone's behavior, appearance, or choices, we seem to have an innate tendency to form opinions and judgments. But what does the Bible have to say about this all-too-common human trait?`
    },
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
    ]),

  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    };
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    goto (routername) {
      window.location.href = `/${routername}`
    },
    // 防抖函数 + 滚动
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      const documentHeight = document.documentElement.scrollHeight;// 获取文档的总高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop; // 当前滚动的高度
      const windowHeight = window.innerHeight;// 视口的高度
      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        // this.getImages();
      }
    },
    //广告相关start
    shouldInsertAd (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig?.[pos].indexOf(position) //TODO: 修改adPosConfig.home的字段为你设置的

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldInsertAdDeviece (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.deviceType == 'mobile' ? this.adPosConfig?.[pos].indexOf(position) : this.adPosConfig?.[`${pos}PC`].indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig?.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds(); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);

      if (!this.observedAds) {
        this.observedAds = new Set();
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;
        if (adElement && !this.observedAds.has(adElement)) {
          observer.observe(adElement);
          this.observedAds.add(adElement);
          console.log('Observing new ad element:', adElement);
          console.log(this.observedAds);
        }
      });

    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //客户端交互 1121已优化版本
    initAdTracking () {
      this.activeIframe = null; // 存储最近被点击的 iframe
      this.iframes = new Map(); // 存储已添加的 iframe

      // 监听 iframe 的插入
      this.watchForIframeInsertion();

      // 设置页面可见性变化的处理
      this.setupVisibilityTracking();
    },
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.addIframe(node);
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    addIframe (iframeElement) {
      if (this.iframes.has(iframeElement)) {
        return; // 已经添加过，直接返回
      }

      this.iframes.set(iframeElement, {
        hasTracked: false,
        adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
      });

      // 如果还没有启动定时器，启动检查点击的定时器
      if (!this.checkInterval) {
        this.checkInterval = setInterval(this.checkIframeClick, 200);
      }
    },
    checkIframeClick () {
      const activeElement = document.activeElement;
      if (activeElement && this.iframes.has(activeElement)) {
        const iframeData = this.iframes.get(activeElement);
        if (!iframeData.hasTracked) {
          this.handleIframeClick(activeElement);
          iframeData.hasTracked = true;
          this.activeIframe = { element: activeElement, ...iframeData };
        }
      }
    },
    handleIframeClick (iframeElement) {
      console.log('Iframe 点击触发了!', iframeElement);
      const adContainer = iframeElement.closest('ins.adsbygoogle');

      if (adContainer) {
        // 获取父级 ins 元素的边界矩形
        const insRect = adContainer.getBoundingClientRect();

        // 计算父级 ins 元素相对于页面左上角的位置和高度
        const transformData = {
          insX: insRect.left,
          insY: insRect.top,
          insHeight: insRect.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        };

        // 调用核心功能
        this.$adClick(JSON.stringify(transformData));
        this.trackEvent('ad_iframe_click', 'click', {
          'data-ad-slot': adContainer.dataset.adSlot,
        });
      }
    },
    setupVisibilityTracking () {
      this.visibilityChangeHandler = () => {
        if (document.visibilityState === 'visible') {
          console.log('页面激活状态，返回页面');
          this.activeIframe = null; // 重置最近被点击的 iframe 记录
        } else {
          console.log('页面非激活状态');
          if (this.activeIframe) {
            // 上报逻辑
            this.trackEvent('ad_iframe_click_leave', 'click', {
              'data-ad-slot': this.activeIframe.adSlot,
            });
          }
        }
      };

      document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    },
    trackEvent (eventName, eventValue, dataAttributes) {
      // 事件追踪逻辑
      console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

      // 调用核心功能
      if (typeof this.$eventrack === 'function') {
        this.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //客户端交互

  }
};
</script>
